<template>
  <base-layout>
    <!--header-bar></header-bar-->
    <ion-searchbar
        show-cancel-button="focus"
        @ionChange="filter(search)"
        :value="search"
        @ionInput="search = $event.target.value;"
    ></ion-searchbar>

   <div class="day-selector">
      <div class="day-selector-item position-relative" v-for="(value, key) in days" :key="key" :value="key"
           style="" mode="md" @click="activeDay = key">
        <div class="day-text d-md-none position-relative">
          <div class="small-badge" v-if="searchDone">{{value.filteredSessionCount}}</div>

          {{ moment(key).format('ddd') }}</div>
        <div v-if="activeDay == key" class="day-number-selected">
          <span class="punkt-selected d-md-none">{{ moment(key).format('D') }} </span>

          <div style="color:white"
               class="d-none d-md-flex justify-content-center large-selected">
            <div class="label-wrapper">
              <div class="label-badge position-absolute top-0 end-0" v-if="searchDone">{{value.filteredSessionCount}}</div>
              <span>{{ moment(key).format('dddd, MMMM D') }}</span>
            </div>
          </div>
        </div>
        <div v-else class="day-number">
          <span class="d-md-none">{{ moment(key).format('D') }}</span>

          <div style="color:white"
               class="d-none d-md-flex justify-content-center">
            <div class="label-wrapper">
              <div class="label-badge position-absolute top-0 end-0" v-if="searchDone">{{value.filteredSessionCount}}</div>
              <span>{{ moment(key).format('dddd, MMMM D') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ion-content>
      <div class="container-fluid p-0 p-md-3" v-if="activeDay && days[activeDay]">
        <div
            class="row row-cols-1 row-cols-md-3  row-cols-lg-4 row-cols-xl-5 g-2 g-md-4 row-eq-height justify-content-start">
          <template v-for="session in days[activeDay].sessions" :key="session.id">
            <session-item :session="session" v-if="session"></session-item>
          </template>

        </div>
      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent, IonSearchbar, alertController, isPlatform} from '@ionic/vue';
import {defineComponent} from 'vue';
import {menuController} from "@ionic/vue";
import {mapActions, mapState} from 'vuex';
import moment from 'moment';
import {gridOutline,locationOutline} from 'ionicons/icons';
import SessionItem from "@/components/SessionItem";
//moment.locale("de");
export default defineComponent({
  name: 'Home',
  data() {
    return {
      sessions: [],
      days: {},
      activeDay: '',
      search: "",
      filtered: [],
      location:null,
    }
  },

  setup() {
    return {
      gridOutline,
      locationOutline
    }
  },
  components: {
    IonContent,
    IonSearchbar,
    "session-item":SessionItem
  },
  computed: {
    ...mapState('sync', ['updateFinished']),
    filteredSessions: function () {
      return this.filtered.length ? this.filtered : this.sessions;
    }
  },
  methods: {
    ...mapActions('sessions', ['getAllSessions', 'getSessionsForLocation']),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    openMenu() {
      menuController.open("app-menu")
    },

    filter(term) {
      if (term.length > 0) {
        this.filtered = this.sessions.filter(session =>
            Object.keys(session).some(k =>
                session[k]
                    ? session[k].toString().toLowerCase().includes(term.toLowerCase())
                    : ""
            )
        );
        this.filtered = this.groupList(this.filtered, 'start_time');
        this.days = {};
        this.prepareDays(this.filtered);
      } else {
        this.filtered = [];
        this.resetList();
      }
    },
    resetList() {
      this.days = {};
      this.prepareDays(this.sessions);
    },
    async fetchSessions() {

      this.sessions = (await this.getSessionsForLocation(this.location)).sort((a, b) => {
        return new Date(a.start_time) - new Date(b.start_time);
      });

      this.sessions = this.groupList(this.sessions, 'start_time');
      this.days = {};
      this.prepareDays(this.sessions);
    },
    groupList(list, selector) {
      let letters = [];
      let currentCharCode = '2010-01-01T00:00:00'; //$filter('date')(date, format, timezone)
      let sortedList = [];

      function addLetter(code) {
        let letter = code
        sortedList.push({
          isHead: true,
          head: letter,
          start_time: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector];
          let difference = new Date(itemCharCode) - new Date(currentCharCode);
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });
      return sortedList;
    },
    prepareDays(sessions) {
      for (let i = 0; i < sessions.length; i++) {
        let session = sessions[i];
        if (session.start_time) {
          let start = session.start_time.split('T')[0];
          if (!this.activeDay) {
            this.activeDay = start;
          }
          if (!this.days[start]) {
            this.days[start] = {};
            this.days[start].label = moment(start).format('dddd, MMMM D')
            this.days[start].sessions = [];
          }
          this.days[start].sessions.push(session);
        }
      }
    },

    getChairPicture(session) {
      let link = ''
      if (session.chair && session.chair[0] && session.chair[0].vitae_picture) {
        link = 'https://fileserver-188ab.kxcdn.com/file/' + session.chair[0].vitae_picture.split('/')[4];
      }
      return link;
    },

    changeToMap() {
      this.$router.push('/app/locationmap/' + this.$route.params.id);
    },

    async showDisclaimer(session) {
      if (session.disclaimer && session.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: session.title,
              message: '<strong>' + session.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(session.title);
                    this.$router.push('/app/sessions/' + session.id);
                  },
                },
              ],
            });
        return alert.present();
      } else {
        this.$router.push('/app/sessions/' + session.id);
      }

    }


  },
  async created() {
    this.location = this.$route.params.id;
    this.fetchSessions();
    this.moment = moment;
  },
  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(to) {
        if (to.name == 'SessionByLocation') {
          this.isIos = isPlatform('ios')
          this.location = to.params.id;
          this.fetchSessions();
        }
      }
    },
    updateFinished: async function (newVal) {
      if (newVal) {
        this.fetchSessions()
      }
    },
  },
});
</script>
<style lang="scss">
.day-selector{
    background: #035797;
    width: 100%;
    padding: 10px;
    padding-top: 10px;
    display: flex;
   flex-direction: row;
   align-items: center;
  }

  .day-selector:hover{
    cursor: pointer;
  }

  .day-selector-item{
    text-align: center;
    width: 100%;
     background: #035797;
    position: relative;
    display: block; 
  }


  .day-text{
     width: 100%;
     text-transform: uppercase;
     font-weight: 600;
     color: white;
  }
  .day-number{
      width: 100%;
      color: white;

  }
  .day-number-selected{
      color: #035797;
  }

  .punkt-selected {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}
.large-selected{
font-weight: 900;
}
</style>
